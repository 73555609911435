import React from "react";
import Modal from "./modal";
import CreditApplicationForm from "../forms/credit-application-form";

export default function CreditAppModal({ open, onClose }) {
  console.log("CreditAppModal rendered. open =", open);

  if (!open) return null;

  return (
    <Modal onClose={onClose} darken containerClassName="w-full max-w-5xl mx-auto max-h-[90vh] overflow-y-auto">
      <div className="p-4 flex flex-col">
        <h2 className="text-2xl font-bold mb-4">Credit Application</h2>
        <CreditApplicationForm /> 
      </div>

    </Modal>
  );
}