import React, { useEffect, useState } from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { api } from '../api.service';

export default function ContentCard({ contentItem }) {
  const [metadata, setMetadata] = useState(null);

  const { title, description, url, category } = contentItem;

  useEffect(() => {
    let isMounted = true;
    if (url) {
      api(`${process.env.REACT_APP_API_URL}/app/content-link`, {
        data: { url: url },
      })
        .then((res) => {
          if (isMounted) setMetadata(res);
        })
        .catch(() => {
          // If there's an error, do nothing or set fallback
        });
    }
    return () => {
      isMounted = false;
    };
  }, [url]);

  // Handler to open link in new tab
  const handleClick = () => {
    if (url) window.open(url, '_blank');
  };

  // If no images from metadata, fallback to something else
  const imageUrl =
    (metadata?.images && metadata.images[0]) ||
    'https://via.placeholder.com/300x180.png?text=No+Preview';

  return (
    <div
      className="relative flex flex-row w-full p-4 bg-primary rounded-lg shadow border border-gray-200 hover:shadow-lg transition-shadow cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-32 h-32 flex-shrink-0 overflow-hidden rounded-md bg-gray-100 flex items-center justify-center">
        <img
          src={imageUrl}
          alt="preview"
          className="object-cover w-full h-full"
        />
      </div>
      <div className="flex-1 pr-3 ml-8">
        <div className="flex items-start justify-between">
          {category && (
            <span className="uppercase text-xs font-semibold text-blue-400">
              {category}
            </span>
          )}
          {/* External link icon in top-right */}
          <ArrowTopRightOnSquareIcon className="absolute w-5 h-5 text-gray-500 top-2 right-2" />
        </div>
        {/* Title */}
        <h2 className="mt-1 mb-1 text-base font-semibold text-gray-800 line-clamp-2">
          {title}
        </h2>
        {/* Description */}
        <p className="text-sm text-gray-600 line-clamp-3">
          {description}
        </p>
      </div>
    </div>
  );
}