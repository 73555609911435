import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { api } from '../api.service';

export default function StripeSubscriptionButton({ user }) {
  const [loading, setLoading] = useState(false);

  async function createSubscription() {
    try {
      setLoading(true);
      const res = await api(`${process.env.REACT_APP_API_URL}/stripe/create-checkout-session`, {
        lookup_key: process.env.REACT_APP_STRIPE_LOOKUP_KEY,
      });
      if (res && res.url) {
        window.location.assign(res.url);
      } else {
        toast.error('Unable to start subscription checkout');
      }
    } catch (err) {
      console.error('Error creating subscription:', err);
      toast.error('Error creating subscription');
    } finally {
      setLoading(false);
    }
  }

  async function manageSubscription() {
    try {
      setLoading(true);
      const res = await api(`${process.env.REACT_APP_API_URL}/stripe/create-portal-session`, {});
      if (res && res.url) {
        window.location.assign(res.url);
      } else {
        toast.error('Unable to manage subscription');
      }
    } catch (err) {
      console.error('Error managing subscription:', err);
      toast.error('Error managing subscription');
    } finally {
      setLoading(false);
    }
  }

  const subscriptionStatus = user?.stripeSubscriptionStatus || 'inactive';
  const hasActiveSubscription = [
    'active',
    'trialing',
    'past_due',
    'unpaid',
    'incomplete'
  ].includes(subscriptionStatus);

  // Similar style to QuickBooks/Plaid: inline with border, small text
  return (
    <button
      onClick={hasActiveSubscription ? manageSubscription : createSubscription}
      disabled={loading}
      className="inline-flex items-center px-6 py-3 rounded-md border border-gray-300 bg-primary text-sm font-semibold text-gray-700 hover:bg-gray-50 transition-all"
    >
      {loading ? 'Loading...' : hasActiveSubscription ? 'Manage Subscription' : 'Setup Subscription'}
    </button>
  );
}