import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';
import { getStatusClass } from '../utils';

const EXPORT_TABLE_COLUMNS = [
    'Date of Creation',
    'Status',
    'Company Name',
    'Contact Info',
];

const CREDIT_APPLICATION_TABLE_COLUMN = [
    {
        Header: 'Date of Creation',
        accessor: 'createdAt',
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
            <span className={`${getStatusClass(value)} capitalize font-bold`}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Company Name',
        accessor: 'companyName',
    },
    {
        Header: 'Contact Info',
        accessor: 'contactInfo',
    },
    {
        Header: 'Actions',
        accessor: 'actions',
    },
];

const CellViewDetails = ({ viewDetailsCallback }) => (
    <span
        className="underline cursor-pointer text-blue-500"
        onClick={viewDetailsCallback}
    >
        View Details
    </span>
);

const CreditApplicationList = () => {
    const [applications, setApplications] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/credit-application`
            );
            setApplications(result);
        } catch (err) {
            setError('Error loading data');
            toast.error('Failed to load credit applications');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;

    // Separate arrays for each status
    const rejectedApps = applications.filter(a => a.status?.toLowerCase() === 'rejected');
    const approvedApps = applications.filter(a => a.status?.toLowerCase() === 'verified');
    const pendingApps = applications.filter(a => {
        const s = a.status?.toLowerCase();
        return s === 'pending' || s === 'incomplete';
    });

    // Sort newest first (descending by createdAt)
    const sortDesc = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);
    rejectedApps.sort(sortDesc);
    approvedApps.sort(sortDesc);
    pendingApps.sort(sortDesc);

    return (
        <div className='flex flex-col gap-10 px-4 mt-4 pb-36'>
            <ToastContainer position="top-right" autoClose={2000} />
            <h1 className='text-2xl font-bold'>Account Applications</h1>
            {/* Pending Applications */}
            <div>
                <h2 className="text-2xl font-bold mb-4">Pending Applications</h2>
                <Table
                    columns={CREDIT_APPLICATION_TABLE_COLUMN}
                    exportColumns={EXPORT_TABLE_COLUMNS}
                    data={pendingApps.map((application) => ({
                        createdAt: new Date(application.createdAt).toLocaleDateString(),
                        status: application.status || 'Unknown',
                        companyName: application.applicationData?.companyLegalName ?? '',
                        contactInfo: application.applicationData?.phoneNumber ?? '',
                        actions: (
                            <CellViewDetails
                                key={application._id}
                                viewDetailsCallback={() =>
                                    navigate(
                                        `/admin/credit-application/detail/\${application._id}`
                                    )
                                }
                            />
                        ),
                    }))}
                />
            </div>

            {/* Approved Applications */}
            <div>
                <h2 className="text-2xl font-bold mb-4">Approved Applications</h2>
                <Table
                    columns={CREDIT_APPLICATION_TABLE_COLUMN}
                    exportColumns={EXPORT_TABLE_COLUMNS}
                    data={approvedApps.map((application) => ({
                        createdAt: new Date(application.createdAt).toLocaleDateString(),
                        status: application.status || 'Unknown',
                        companyName: application.applicationData?.companyLegalName ?? '',
                        contactInfo: application.applicationData?.phoneNumber ?? '',
                        actions: (
                            <CellViewDetails
                                key={application._id}
                                viewDetailsCallback={() =>
                                    navigate(
                                        `/admin/credit-application/detail/\${application._id}`
                                    )
                                }
                            />
                        ),
                    }))}
                />
            </div>

            <div className="flex flex-col gap-10 px-4 mt-4">
                {/* Rejected Applications */}
                <div>
                    <h2 className="text-2xl font-bold mb-4">Rejected Applications</h2>
                    <Table
                        columns={CREDIT_APPLICATION_TABLE_COLUMN}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={rejectedApps.map((application) => ({
                            createdAt: new Date(application.createdAt).toLocaleDateString(),
                            status: application.status || 'Unknown',
                            companyName: application.applicationData?.companyLegalName ?? '',
                            contactInfo: application.applicationData?.phoneNumber ?? '',
                            actions: (
                                <CellViewDetails
                                    key={application._id}
                                    viewDetailsCallback={() =>
                                        navigate(
                                            `/admin/credit-application/detail/\${application._id}`
                                        )
                                    }
                                />
                            ),
                        }))}
                    />
                </div>




            </div>
        </div>
    );
};

export default CreditApplicationList;