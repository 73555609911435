import { PlusIcon } from '@heroicons/react/20/solid';
import { ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import '@lendica/ibranchtrigger';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from './api.service';
import Button from './components/button';
import PayLaterButton from './components/pay-later-button';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export default function Bills() {
  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');

  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app/bill`).then((x) => {
      setData(x);
    });
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setUserData(x);
    });
  }, []);

  const linksRef = useRef();
  const linksVisible = useIntersectionObserver(linksRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (linksVisible) {
      linksRef.current.classList.remove('invisible');
      linksRef.current.classList.add('animate-fade-up');
    }
  }, [linksVisible]);

  const contentRef = useRef();
  const contentVisible = useIntersectionObserver(contentRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (contentVisible) {
      contentRef.current.classList.remove('invisible');
      contentRef.current.classList.add('animate-fade-up');
    }
  }, [contentVisible]);

  useEffect(() => {
    if (window.lendica && userData && !initialised && !buildrfiFinancingEnabled) {
      setInitialised(true);
      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: userData.companyData.id,
        company_name: userData.companyData.companyName,
      };
      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
      });
    }
  }, [window.lendica, userData]);

  const openFundNow = (id, total) => {
    window.lendica.ibranch.openPayLater(id, total);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div>
        <div className="w-full max-w-5xl p-5 mx-auto sm:p-0">
          <div className=" text-4xl font-semibold text-gray-800">
            Bills
          </div>

          <BillsTable data={data} openBill={openFundNow} />
        </div>
      </div>
    </div>
  );
}

export const BillsTable = ({ data, openBill }) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');

  const [sortAlg, setSortAlg] = useState({ param: '', isAscending: true });

  const [total, setTotal] = useState();

  const [list, setList] = useState([]);

  useEffect(() => {
    let subtotal = 0;
    let tax = 0;
    let total = 0;
    if (data?.length)
      for (let p of data) {
        if (p.subtotal) subtotal += +p.subtotal;
        if (p.tax) tax += +p.tax;
        total += (+p.subtotal || 0) + (+p.tax || 0);
      }

    setTotal({ subtotal, tax, total });
  }, [data]);

  useEffect(() => {
    setList(
      data
        ?.sort((a, b) => sortFn(a, b, sortAlg))
        .map((x) => ({
          order_id: x.invoiceNumber,
          vendor: x.vendorName,
          order_date: x.orderDate
            ? moment(x.orderDate).format('MMMM Do YYYY')
            : '-',
          due_date: x.dueDate ? moment(x.dueDate).format('MMMM Do YYYY') : '-',
          paid: x.paid ? 'Yes' : 'No',
          subtotal: safeCurrency(x.subtotal),
          tax: safeCurrency(x.tax),
          total: safeCurrency(x.subtotal + x.tax),
        })) ?? [],
    );
  }, [data, sortAlg]);

  const exportToCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: Object.keys(list[0] ?? {}),
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(list);
  };

  return (
    <>
      {data && (
        <>
          {!data?.length && (
            <div className="border flex justify-center rounded shadow bg-primary animate-fade-up animate-delay-300">
              <Empty></Empty>
            </div>
          )}

          {data?.length > 0 && (
            <div className="flex justify-end mb-5 flex-row space-x-2">
              <Button text="Add Bill" onClick={() => navigate('/bill/create')} />
              <Button text="Export to CSV" onClick={() => exportToCsv()} />
            </div>
          )}
          {data?.length > 0 && (
            <div className="grid grid-cols-4 rounded shadow lg:grid-cols-9 bg-primary animate-fade-up animate-delay-300 border">
              <div className="items-center hidden p-5 font-semibold tracking-tight border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Order Id"
                  accessor="invoiceNumber"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Vendor"
                  accessor="vendorName"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Order Date"
                  accessor="orderDate"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Due Date"
                  accessor="dueDate"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Paid"
                  accessor={(x) => x.paid}
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Subtotal"
                  accessor="subtotal"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Tax"
                  accessor="tax"
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-gray-800 lg:block border-slate-500">
                <SortToggle
                  text="Total"
                  accessor={(x) => x.subtotal + x.tax}
                  state={sortAlg}
                  onChange={(next) => setSortAlg(next)}
                ></SortToggle>
              </div>
              <div className="hidden p-5 font-semibold tracking-tight text-right border-b-2 text-gray-800 lg:block border-slate-500"></div>

              {data
                ?.filter((x, i) => (showMore ? true : i < 10))
                .sort((a, b) => sortFn(a, b, sortAlg))
                .map((x) => (
                  <>
                    <div
                      onClick={() => navigate('/bill/' + x._id)}
                      className="col-span-2 p-5 font-medium tracking-wide border-b cursor-pointer text-gray-800 lg:col-span-1 hover:underline border-slate-500"
                    >
                      {x.invoiceNumber}
                    </div>
                    <div className="hidden p-5 font-medium border-b text-gray-800 lg:block border-slate-500 break-words">
                      {x.vendorName}
                    </div>
                    <div className="hidden p-5 font-medium border-b text-gray-800 lg:block border-slate-500 break-words">
                      {x.orderDate
                        ? moment(x.orderDate).format('MMMM Do YYYY')
                        : '-'}
                    </div>
                    <div className="hidden p-5 font-medium border-b text-gray-800 lg:block border-slate-500 break-words">
                      {x.dueDate
                        ? moment(x.dueDate).format('MMMM Do YYYY')
                        : '-'}
                    </div>
                    <div className="hidden p-5 font-medium text-right border-b text-gray-800 lg:block border-slate-500 break-words">
                      {x.paid ? 'Yes' : 'No'}
                    </div>

                    <div className="hidden p-5 font-medium text-right border-b text-gray-800 lg:block border-slate-500 break-words">
                      {safeCurrency(x.subtotal)}
                    </div>
                    <div className="hidden p-5 font-medium text-right border-b text-gray-800 lg:block border-slate-500 break-words">
                      {safeCurrency(x.tax)}
                    </div>
                    <div className="hidden p-5 font-medium text-right border-b text-gray-800 lg:block border-slate-500 break-words">
                      {safeCurrency(x.subtotal + x.tax)}
                    </div>
                    <div className="hidden p-5 font-medium text-right border-b text-gray-800 lg:block border-slate-500 break-words">
                      {buildrfiFinancingEnabled ? <PayLaterButton preload={true} bill={x} /> :
                        <Button
                          onClick={() => openBill(x._id, x.subtotal + x.tax)}
                          className="text-xs whitespace-nowrap"
                          text="Pay Later"
                        />
                      }
                    </div>
                    <div className="col-span-2 p-5 border-b border-slate-500 lg:hidden break-words">
                      <div className="text-xs text-gray-800">
                        Vendor -{' '}
                        <span className="text-sm font-medium text-gray-800">
                          {x.vendorName}
                        </span>
                      </div>
                      <div className="text-xs text-gray-800">
                        Total -{' '}
                        <span className="text-sm font-medium text-gray-800">
                          {safeCurrency(x.subtotal + x.tax)}{' '}
                          <span className="text-xs">
                            ({safeCurrency(x.subtotal)}+ {safeCurrency(x.tax)})
                          </span>
                        </span>
                      </div>
                      <div className="text-xs text-gray-800">
                        Paid -{' '}
                        <span className="text-sm font-medium text-gray-800">
                          {safeCurrency(x.totalPrice)}
                        </span>
                      </div>

                      {buildrfiFinancingEnabled ? <PayLaterButton bill={x} /> :
                        <Button
                          onClick={() => openBill(x._id, x.subtotal + x.tax)}
                          className="text-xs whitespace-nowrap"
                          text="Pay Later"
                        />
                      }
                    </div>
                  </>
                ))}

              {data?.length > 10 && !showMore && (
                <>
                  <div
                    className="col-span-4 p-2 underline cursor-pointer lg:col-span-6"
                    onClick={() => setShowMore(true)}
                  >
                    Show More
                  </div>
                </>
              )}

              <div className="hidden px-5 pt-3 text-gray-200 border-t-2 lg:block border-slate-500">
                <Button
                  onClick={() => navigate('/bill/create')}
                  text={
                    <>
                      {' '}
                      <PlusIcon className="w-5 h-5"></PlusIcon> Bill
                    </>
                  }
                />
              </div>
              <div className="col-span-2 p-5 text-xl font-semibold leading-6 tracking-wide text-right border-t-2 text-gray-800 lg:col-span-4 border-slate-500">
                Total
              </div>
              <div className="hidden p-5 font-semibold text-right border-t-2 text-gray-800 lg:block border-slate-500">
                ${total.subtotal.toLocaleString()}
              </div>
              <div className="hidden p-5 font-semibold text-right border-t-2 text-gray-800 lg:block border-slate-500">
                ${total.tax.toLocaleString()}
              </div>
              <div className="hidden p-5 font-semibold text-right border-t-2 text-gray-800 lg:block border-slate-500">
                ${total.total.toLocaleString()}
              </div>
              <div className="hidden p-5 font-semibold text-right border-t-2 text-gray-800 lg:block border-slate-500"></div>

              <div className="col-span-2 p-5 border-t-2 border-slate-500 lg:hidden">
                <div className="text-xs text-gray-800">
                  Aggregate Subtotal -{' '}
                  <span className="text-sm font-medium text-gray-800">
                    ${total.subtotal.toLocaleString()}
                  </span>
                </div>
                <div className="text-xs text-gray-800">
                  Aggregate Tax -{' '}
                  <span className="text-sm font-medium text-gray-800">
                    ${total.tax.toLocaleString()}
                  </span>
                </div>
                <div className="text-xs text-gray-800">
                  Total -{' '}
                  <span className="text-sm font-medium text-gray-800">
                    ${total.total.toLocaleString()}
                  </span>
                </div>
              </div>

              <div className="col-span-4 p-5 text-gray-200 border-t-2 lg:hidden border-slate-500">
                <Button
                  onClick={() => navigate('/bill/create')}
                  text={
                    <>
                      {' '}
                      <PlusIcon className="w-8 h-8"></PlusIcon> Bill
                    </>
                  }
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

function Empty() {
  const navigate = useNavigate();
  return (
    <div className="my-10 text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-800">No Bills</h3>
      <p className="mt-1 text-sm text-gray-700">
        Get started by creating a new bill.
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/bill/create')}
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold bg-yellow-500 border-2 border-yellow-500 rounded-md text-gray-800"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Bill
        </button>
      </div>
    </div>
  );
}

function SortToggle({
  text,
  accessor,
  state,
  onChange,
  className,
  defaultAscending = true,
}) {
  return (
    <div
      className={`flex justify-center w-full h-full items-center text-center cursor-pointer select-none ${className}`}
      onClick={() =>
        onChange({
          accessor: accessor,
          isAscending: isCurrentAccessor(accessor, state.accessor)
            ? !state.isAscending
            : defaultAscending,
        })
      }
    >
      {text}{' '}
      <div className="flex items-center justify-center w-6 h-6 ml-1 min-w-6 min-h-6">
        <ArrowUpIcon
          className={`${isCurrentAccessor(accessor, state.accessor) ? 'h-6 w-6 opacity-100' : 'h-0 w-0 opacity-0'} ${state.isAscending ? 'rotate-0' : 'rotate-180'} transition-all`}
        />
        <ArrowsUpDownIcon
          className={`${isCurrentAccessor(accessor, state.accessor) ? 'h-0 w-0 opacity-0' : 'h-5 w-5 opacity-100'} text-gray-400 transition-all`}
        />
      </div>
    </div>
  );
}

function isCurrentAccessor(source, target) {
  if (typeof source !== typeof target) {
    return false;
  }

  if (typeof source === 'function') {
    return source.toString() === target.toString();
  }

  return source === target;
}

function sortFn(a, b, { accessor, isAscending }) {
  if (typeof accessor === 'function') {
    a = accessor(a);
    b = accessor(b);
  } else {
    a = a[accessor];
    b = b[accessor];
  }

  if (a === undefined) return b === undefined ? 0 : 1;
  if (b === undefined) return -1;

  return (a > b ? 1 : -1) * (isAscending ? -1 : 1);
}

function safeCurrency(value) {
  if (value === 0) {
    return '$0';
  }
  if (!value) {
    return '-';
  }
  return '$' + Number(value).toLocaleString();
}
function safeNumber(value) {
  if (value === 0) {
    return '0';
  }
  if (!value) {
    return '-';
  }
  return Number(value).toLocaleString();
}

function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}
