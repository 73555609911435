import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowDownIcon,
  LinkIcon,
  PencilIcon,
  UserGroupIcon,
  InboxStackIcon,
  XMarkIcon,
  BuildingOfficeIcon,
  IdentificationIcon,
  WrenchScrewdriverIcon,
  BanknotesIcon
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import logo from './logo.png';
import { IconLayoutSidebar } from '@tabler/icons-react';
// import { DocumentIcon } from '@heroicons/react/24/outline'; // Uncomment if needed

const navigation = [
  { name: 'Users', href: '/admin/users', icon: UserGroupIcon },
  { name: 'Businesses', href: '/admin/business', icon: BuildingOfficeIcon },
  { name: 'Projects', href: '/admin/project', icon: WrenchScrewdriverIcon },
  { name: 'Affiliate Links', href: '/admin/affiliate-links', icon: LinkIcon },
  { name: 'Content Links', href: '/admin/content', icon: PencilIcon },
  { name: 'Customers', href: '/admin/customers', icon: InboxStackIcon },
  { name: 'Vendors', href: '/admin/vendors', icon: InboxStackIcon },
  { name: 'Account Applications', href: '/admin/credit-application', icon: IdentificationIcon },
  { name: 'Financing Requests', href: '/admin/financing-requests', icon: BanknotesIcon },
  { name: 'Loans', href: '/admin/loans', icon: BanknotesIcon },
  // { name: 'Documents', href: '/admin/documents', icon: DocumentIcon },
];

const secondaryNavigation = [
  { name: 'Logout', href: '/logout', icon: ArrowDownIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AdminLayout() {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <>
      <div className="min-h-full">
        {/* Mobile Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-opacity-75 bg-slate-600" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-slate-700">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="w-6 h-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4">
                    <img className="object-cover w-full h-12" src={logo} alt="Logo" />
                  </div>
                  <nav
                    className="flex-shrink-0 h-full mt-5 overflow-y-auto divide-y divide-slate-800"
                    aria-label="Sidebar"
                  >
                    <div className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            pathname.endsWith(item.href)
                              ? 'bg-slate-700 text-white'
                              : 'text-gray-800 hover:text-white hover:bg-slate-600',
                            'group flex items-center px-2 py-2 text-base font-medium rounded',
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          <item.icon
                            className="flex-shrink-0 w-6 h-6 mr-4 text-gray-200"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                    <div className="pt-6 mt-6">
                      <div className="px-2 space-y-1">
                        {secondaryNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center px-2 py-2 text-base font-medium rounded group text-gray-800 hover:bg-slate-600 hover:text-white"
                          >
                            <item.icon
                              className="w-6 h-6 mr-4 text-gray-200"
                              aria-hidden="true"
                            />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </nav>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true" />
            </div>
          </Dialog>
        </Transition.Root>

        {/* Desktop Sidebar */}
        <div
          className={classNames(
            'hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col transition-all duration-300 ease-in-out',
            sidebarCollapsed ? 'lg:w-20' : 'lg:w-64',
          )}
        >
          <div className="flex flex-col flex-grow px-2 pt-10 pb-4 bg-slate-200 overflow-y-auto">
            {/* Sidebar Header with Toggle */}
            <div className="flex items-center justify-between flex-shrink-0 px-4">
              { !sidebarCollapsed && <img
                className={classNames(
                  'object-cover transition-all duration-300 ease-in-out',
                  sidebarCollapsed ? 'w-10 h-auto' : 'w-44 h-auto'
                )}
                src={logo}
                alt="Logo"
              />}
              <button
                type="button"
                onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                className="p-2 text-gray-500 hover:text-gray-800 focus:outline-none"
              >
                <span className="sr-only">Toggle sidebar</span>
                <IconLayoutSidebar className="w-6 h-6" />
              </button>
            </div>

            <nav
              className="flex flex-col flex-1 mt-5 overflow-y-auto divide-y divide-slate-300"
              aria-label="Sidebar"
            >
              <div className="flex-1 px-2 space-y-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      pathname.endsWith(item.href)
                        ? 'bg-slate-700 text-white'
                        : 'text-gray-500 hover:text-gray-800 hover:bg-slate-300',
                      'group flex items-center px-4 py-3 text-sm leading-6 font-medium rounded transition-colors duration-200 ease-in-out'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon
                      className="flex-shrink-0 w-6 h-6 mr-4"
                      aria-hidden="true"
                    />
                    {/* Hide the text when collapsed */}
                    {!sidebarCollapsed && item.name}
                  </Link>
                ))}
              </div>
              <div className="pt-6 mt-6">
                <div className="px-2 space-y-1">
                  {secondaryNavigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        pathname.endsWith(item.href)
                          ? 'bg-slate-700 text-white'
                          : 'text-gray-500 hover:text-gray-800 hover:bg-slate-300',
                        'group flex items-center px-4 py-3 text-sm leading-6 font-medium rounded transition-colors duration-200 ease-in-out'
                      )}
                    >
                      <item.icon className="w-6 h-6 mr-4" aria-hidden="true" />
                      {!sidebarCollapsed && item.name}
                    </a>
                  ))}
                </div>
              </div>
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex flex-col flex-1 min-h-screen lg:pl-0" style={{ marginLeft: sidebarCollapsed ? '5rem' : '16rem', transition: 'margin-left 0.3s ease-in-out' }}>
          <main className="flex-1 bg-slate-50">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}