import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function LoanCard({ loan, transactions, formatCurrency, formatDate }) {
  const [expanded, setExpanded] = useState(false);

  // Determine nextPayment
  let nextPayment = null;
  if (Array.isArray(loan.paymentSchedule)) {
    nextPayment = loan.paymentSchedule.find((p) => p.status === "unpaid");
  }

  // References
  const projectId = loan.project?._id;
  const billId = loan.bill?._id;

  const termWeeks = loan.termLengthWeeks || 0;
  const interestRatePct = (loan.interestRate * 100).toFixed(2) + "%";

  const renderStatusBadge = (status) => {
    let base = "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2";
    switch (status) {
      case "active":
      case "paid":
        return <span className={base + " bg-green-100 text-green-800"}>{status}</span>;
      case "overdue":
        return <span className={base + " bg-red-100 text-red-800"}>{status}</span>;
      case "partially_paid":
        return <span className={base + " bg-yellow-100 text-yellow-800"}>{status}</span>;
      case "inactive":
      default:
        return <span className={base + " bg-gray-100 text-gray-800"}>{status}</span>;
    }
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`border rounded p-4 bg-primary shadow-sm text-sm ${
        loan.status === "inactive" ? "opacity-75" : ""
      }`}
      style={{ userSelect: "none" }}
    >
      <div className="flex justify-between cursor-pointer" onClick={toggleExpand}>
        <div>
          <h2 className="text-md font-semibold mb-1 flex items-center space-x-2">
            <span>Loan #{loan._id}</span>
            {renderStatusBadge(loan.status)}
          </h2>
          <p className="text-xs text-gray-600">
            Principal: {formatCurrency(loan.principal)} — Term: {termWeeks} weeks
          </p>
        </div>
        <div className="flex items-center space-x-4">
          <div className="text-right">
            <p className="text-xs font-medium">Next Balance</p>
            <p className="text-sm font-semibold">
              {nextPayment
                ? formatCurrency(nextPayment.principalBalance)
                : formatCurrency(loan.principal)}
            </p>
          </div>
          {expanded ? (
            <span className="text-gray-500">&#9650;</span>
          ) : (
            <span className="text-gray-500">&#9660;</span>
          )}
        </div>
      </div>

      {expanded && (
        <div className="mt-2">
          {/* Bill & Project references */}
          <div className="text-xs space-y-1 mb-3 text-gray-700">
            {projectId && (
              <div>
                Project: <Link to={`/project/${projectId}`} className="underline text-blue-600">{projectId}</Link>
              </div>
            )}
            {billId && (
              <div>
                Bill: <Link to={`/bill/${billId}`} className="underline text-blue-600">{billId}</Link>
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4 text-xs">
            <div>
              <p className="text-gray-500">Term (Weeks)</p>
              <p className="font-semibold">{termWeeks}</p>
            </div>
            <div>
              <p className="text-gray-500">Interest Rate</p>
              <p className="font-semibold">{interestRatePct}</p>
            </div>
          </div>

          {/* Payment schedule */}
          <div className="mt-4">
            <h3 className="text-sm font-medium">Payment Schedule</h3>
            {Array.isArray(loan.paymentSchedule) && loan.paymentSchedule.length > 0 ? (
              <div className="border rounded mt-2 overflow-x-auto bg-primary">
                <table className="w-full text-xs">
                  <thead className="bg-gray-50 border-b">
                    <tr>
                      <th className="px-4 py-2 text-left">Due Date</th>
                      <th className="px-4 py-2 text-left">Status</th>
                      <th className="px-4 py-2 text-right">Total Due</th>
                      <th className="px-4 py-2 text-right">Balance After</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loan.paymentSchedule.map((p, i) => (
                      <tr key={i} className="border-b">
                        <td className="px-4 py-2">{formatDate(p.dueDate)}</td>
                        <td className="px-4 py-2">{renderStatusBadge(p.status)}</td>
                        <td className="px-4 py-2 text-right">{formatCurrency(p.totalDue)}</td>
                        <td className="px-4 py-2 text-right">{formatCurrency(p.principalBalance)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-xs text-gray-600 mt-2">No payment schedule found</p>
            )}
          </div>

          {/* Transactions */}
          {transactions && transactions.length > 0 && (
            <div className="mt-4">
              <h3 className="text-sm font-medium">Transactions</h3>
              <div className="border rounded bg-primary mt-2 overflow-x-auto">
                <table className="w-full text-xs">
                  <thead className="bg-gray-50 border-b">
                    <tr>
                      <th className="px-4 py-2 text-left">Date</th>
                      <th className="px-4 py-2 text-left">Method</th>
                      <th className="px-4 py-2 text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((t) => (
                      <tr key={t._id} className="border-b">
                        <td className="px-4 py-2">{formatDate(t.createdAt)}</td>
                        <td className="px-4 py-2">{t.paymentMethod || "N/A"}</td>
                        <td className="px-4 py-2 text-right">{formatCurrency(t.amount)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}