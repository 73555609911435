import React, { useState, useEffect } from 'react';
import Modal from './modal';
import { api, api_post, api_update } from '../api.service';
import Loading from './loading';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import FilePreviewWithMagnification from './file-preview-with-magnification';

export default function BillFinancingModal({
    billData,
    onClose,
    currentStep,
    setCurrentStep,
    setBillData,
    creditApplication,
    setCreditApplication,
    loading,
    setLoading,
    financingRequest,
    setFinancingRequest,
}) {
    const [vendorACH, setVendorACH] = useState({
        bankName: '',
        routingNumber: '',
        accountNumber: '',
        accountType: 'Checking',
    });

    const [billPreviewUrl, setBillPreviewUrl] = useState(null);

    useEffect(() => {
        if (currentStep === 2 && billData?.document?.key) {
            console.log('Bill doc key found, fetching preview URL...');
            api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${billData.document.key}`)
                .then((res) => {
                    if (res?.url) {
                        setBillPreviewUrl(res.url);
                    }
                })
                .catch((err) => {
                    console.error('Error fetching Bill doc preview URL', err);
                });
        } else {
            setBillPreviewUrl(null);
        }
    }, [currentStep, billData]);

    const [offers, setOffers] = useState(financingRequest?.offers || []);
    const navigate = useNavigate();

    const handleSubmitACH = async () => {
        try {
            const achInfo = await api_post(`${process.env.REACT_APP_API_URL}/ach-info/`, {
                vendorId: billData.vendor,
                bankName: vendorACH.bankName,
                routingNumber: vendorACH.routingNumber,
                accountNumber: vendorACH.accountNumber,
                accountType: vendorACH.accountType,
                holderName: billData.vendorName,
                owner: billData.vendor,
                type: 'vendor',
            });

            const financingRequestPayload = {
                invoice: billData._id,
                achInfo: achInfo._id,
                project: billData?.project
            };

            const financingRequestResponse = await api_post(`${process.env.REACT_APP_API_URL}/financing-request/`, financingRequestPayload);

            setFinancingRequest(financingRequestResponse);
            console.log("Financing Request", financingRequestResponse);

            const newBillData = {
                ...billData,
                financingData: {
                    financingRequest: financingRequestResponse._id,
                    achInfo: achInfo._id,
                    program: "Pay Later",
                    partner: "BuildrFi",
                },
            };

            await api_update(`${process.env.REACT_APP_API_URL}/app/bill/${billData._id}`, {
                ...newBillData,
            });

            setBillData(newBillData);
            setCurrentStep(3);
        } catch (error) {
            console.error('Error creating financing request:', error);
        }
    };

    const renderBillSummary = () => (
        <div className="bg-primary border rounded-lg px-4 pt-1 flex flex-row-reverse w-3/5 mr-10 gap-x-4">
            <div className="flex flex-col w-1/2">
                <h3 className="text-lg font-semibold mb-4">Bill Summary</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <strong>Invoice Number:</strong>
                    </div>
                    <div>{billData.invoiceNumber || 'N/A'}</div>
                    <div>
                        <strong>Vendor Name:</strong>
                    </div>
                    <div>{billData.vendorName || 'N/A'}</div>
                    <div>
                        <strong>Subtotal:</strong>
                    </div>
                    <div>${Number(billData.subtotal).toFixed(2)}</div>
                    <div>
                        <strong>Tax:</strong>
                    </div>
                    <div>${Number(billData.tax).toFixed(2)}</div>
                    <div>
                        <strong>Total:</strong>
                    </div>
                    <div>${Number(billData.subtotal + billData.tax).toFixed(2)}</div>
                </div>
            </div>

            {billPreviewUrl && (
                <div className="w-1/2 h-full">
                    <strong>File Preview:</strong>
                    <div className="w-full h-full mt-2">
                        <FilePreviewWithMagnification
                            fileUrl={billPreviewUrl}
                            previewLabel="Preview (click to enlarge)"
                            placeholderText="No file to preview"
                            docName={billData.document.key}
                            className="h-full"
                        />
                    </div>
                </div>
            )}
        </div>
    );

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="text-center">
                        {creditApplication?.status === 'pending' ? (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your application is pending verification.
                                </h2>
                                <p className="text-lg">
                                    We will approve your application within <strong>48 hours</strong> of your submission.
                                </p>
                            </>
                        ) : creditApplication?.status !== 'verified' ? (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your application is not complete
                                </h2>
                                <p className="text-lg">
                                    Please ensure your application is complete and try again.
                                </p>
                                <button
                                    className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all"
                                    onClick={() => {
                                        navigate(`/financing`);
                                    }}
                                >
                                    Go Back
                                </button>
                            </>
                        ) : (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your bill is incomplete
                                </h2>
                                <p className="text-lg">
                                    Please ensure this bill has all required information, and try again.
                                </p>
                            </>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-row w-full justify-center items-stretch">
                        {renderBillSummary()}
                        <div className='w-1/4' >
                            <p className="text-lg mb-4 text-center">
                                Please enter the vendor's ACH information to proceed.
                            </p>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmitACH();
                                }}
                            >
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        value={vendorACH.bankName}
                                        onChange={(e) =>
                                            setVendorACH({
                                                ...vendorACH,
                                                bankName: e.target.value,
                                            })
                                        }
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">Account Number</label>
                                    <input
                                        type="text"
                                        value={vendorACH.accountNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,12}$/.test(value)) {
                                                setVendorACH({
                                                    ...vendorACH,
                                                    accountNumber: value,
                                                });
                                            }
                                        }}
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                    {!/^\d{8,12}$/.test(vendorACH.accountNumber) && vendorACH.accountNumber && (
                                        <p className="text-red-600 text-sm">Account Number must be 8-12 digits.</p>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">Routing Number</label>
                                    <input
                                        type="text"
                                        value={vendorACH.routingNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,9}$/.test(value)) {
                                                setVendorACH({
                                                    ...vendorACH,
                                                    routingNumber: value,
                                                });
                                            }
                                        }}
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                    {!/^\d{9}$/.test(vendorACH.routingNumber) && vendorACH.routingNumber && (
                                        <p className="text-red-600 text-sm">Routing Number must be exactly 9 digits.</p>
                                    )}
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded hover:bg-blue-700"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="text-center">
                        <h2 className="text-3xl font-bold mb-4">
                            Thank you for your submission!
                        </h2>
                        <p className="text-lg">
                            We will email you when your offers are ready.
                        </p>
                        <div className="flex justify-center mt-6">
                            <button
                                onClick={() => {
                                    setCurrentStep(4);
                                }}
                                className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded hover:bg-blue-700"
                            >
                                Proceed to Offers
                            </button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <h2 className="text-xl font-bold mb-4 text-center">
                            Available Financing Offers
                        </h2>
                        {loading ? (
                            <p className="text-center">Loading offers...</p>
                        ) : offers && offers.length > 0 ? (
                            <ul className="mb-4 ">
                                {offers.map((offer, index) => (
                                    <li key={offer.id} className="mb-4 border p-8 rounded bg-primary shadow-sm flex flex-row items-center gap-x-8">
                                        <div>
                                            <strong>Amount:</strong> {offer.amount}
                                        </div>
                                        <div>
                                            <strong>Interest Rate:</strong> {offer.interestRate}
                                        </div>
                                        <div>
                                            <strong>Term:</strong> {offer.termLength} weeks
                                        </div>
                                        <button
                                            onClick={() => handleAcceptOffer(index)}
                                            type='button'
                                            className="ml-auto px-4 py-2 text-sm font-semibold text-white bg-green-600 rounded hover:bg-green-700"
                                        >
                                            Accept Offer
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="text-center">
                                <p className="mb-4">
                                    You are currently waiting for offers.
                                </p>
                                <p>
                                    We will notify you as soon as offers are available.
                                </p>
                            </div>
                        )}
                    </div>
                );
            case 5:
                return (
                    <div className="text-center bg-primary">
                        <h2 className="text-3xl font-bold mb-4">
                            Thank you for your business!
                        </h2>
                        <p className="mb-4">
                            We will notify you as soon as your transfer has gone through.
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    const updateFinancingRequest = async (updatedFinancingRequest) => {
        try {
            await api_update(
                `${process.env.REACT_APP_API_URL}/financing-request/${financingRequest._id}`,
                updatedFinancingRequest
            );
        } catch (err) {
            console.error(err);
        }
    };

    const handleAcceptOffer = async (index) => {
        let newOffers = [...offers];
        newOffers[index].status = 'Accepted';
        setOffers(newOffers);

        const updatedFinancingRequest = {
            ...financingRequest,
            offers: newOffers,
            status: 'Approved',
        };
        await updateFinancingRequest(updatedFinancingRequest);

        // Create a new Loan from the accepted offer
        let newLoanId = null;
        const acceptedOffer = newOffers[index];
        try {
            const newLoanResp = await api_post(
                `${process.env.REACT_APP_API_URL}/loan`,
                {
                    data: {
                        principal: parseFloat(acceptedOffer.amount),
                        interestRate: parseFloat(acceptedOffer.interestRate) / 100,
                        termLengthWeeks: parseInt(acceptedOffer.termLength, 10),
                        financingRequest: financingRequest._id,
                        bill: billData._id,
                        project: billData?.project
                    }
                }
            );
            if (newLoanResp && newLoanResp.data && newLoanResp.data.loan) {
                newLoanId = newLoanResp.data.loan._id;
            }
        } catch (loanError) {
            console.error('Error creating a new loan:', loanError);
        }

        // Create a loan snapshot with the accepted offer
        // and store the financingRequest + loan references
        const snapshotData = {
            financingRequest: updatedFinancingRequest,
            acceptedOffer,
            snapshotCreatedAt: new Date().toISOString()
        };
        const loanSnapshotString = JSON.stringify(snapshotData);

        try {
            const response = await api_post(
                `${process.env.REACT_APP_API_URL}/loan-snapshot`,
                {
                    loanSnapshotString,
                    acceptedOffer,
                    financingRequest: updatedFinancingRequest._id,
                    loan: newLoanId
                }
            );
            if (response && response.data && response.data.status === 'success') {
                console.log('Loan snapshot created with accepted offer.');
            } else {
                console.error('Failed to create loan snapshot for accepted offer', response);
            }
        } catch (error) {
            console.error('Error creating loan snapshot for accepted offer', error);
        }

        setCurrentStep(5);
    };

    return (
        <Modal
            onClose={onClose}
            blur={true}
            darken={true}
            containerClassName="w-full max-w-6xl mx-auto p-6 flex flex-col"
        >
            <div className="flex items-center justify-center mb-6">
                <img src={logo} className="w-24" alt="logo" />
                <h1 className="text-3xl ml-4 font-bold">Financing Request</h1>
            </div>
            {loading ? <Loading />
                :
                <div className="p-6 rounded-lg flex-grow flex flex-col">
                    {renderStepContent()}
                </div>
            }
        </Modal>
    );
}