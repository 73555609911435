import React, { useState, useEffect } from 'react';
import { Pencil, Check, X, CreditCard, Building2, Link2, Banknote, PersonStandingIcon } from 'lucide-react';
import { api, api_post, api_update } from '../api.service';
import TextField from './fields/text-field';
import SelectField from './fields/select-field';
import { toast } from 'react-toastify';
import QuickbooksButton from '../components/quickbooks-button';
import PlaidButton from '../components/plaid-button';
import StripeSubscriptionButton from '../components/stripe-subscription-button';
import { useFeatureFlagEnabled } from 'posthog-js/react';

function ProfileForm() {
  const quickbooksEnabled = useFeatureFlagEnabled('quickbooks_button');
  const plaidEnabled = useFeatureFlagEnabled('plaid_button');

  // Data from /app/settings
  const [userData, setUserData] = useState({
    firstName: 'Missing Data',
    lastName: 'Missing Data',
    email: 'Missing Data',
    businessName: 'Missing Data',
    businessType: 'Missing Data',
    stripeSubscriptionStatus: 'inactive',
  });

  // Data from /ach-info/owner/customer
  const [achData, setAchData] = useState({
    _id: null,
    bankName: '',
    accountType: '',
    routingNumber: '',
    accountNumber: '',
  });

  const [achExists, setAchExists] = useState(false);
  const [isEditingACH, setIsEditingACH] = useState(false);
  const [editACHForm, setEditACHForm] = useState({ ...achData });

  // ---------- Fetch User Data ----------
  async function fetchUserData() {
    try {
      const data = await api(`${process.env.REACT_APP_API_URL}/app/settings`);
      if (data && !data.error) {
        setUserData({
          firstName: data.firstName || 'Missing Data',
          lastName: data.lastName || 'Missing Data',
          email: data.email || 'Missing Data',
          businessName: data.business?.name || 'Missing Data',
          businessType: data.business?.type || 'Missing Data',
          stripeSubscriptionStatus: data.stripeSubscriptionStatus || 'inactive',
        });
        console.log("User data fetched:", data);
      }
    } catch (err) {
      console.error('Failed to fetch user data:', err);
    }
  }

  // ---------- Fetch ACH Data ----------
  async function fetchACHData() {
    try {
      const result = await api(`${process.env.REACT_APP_API_URL}/ach-info/owner/customer`);
      if (result && !result.error) {
        setAchData({
          _id: result._id,
          bankName: result.bankName || '',
          routingNumber: result.routingNumber || '',
          accountNumber: result.accountNumber || '',
          accountType: result.accountType || '',
        });
        setEditACHForm({
          _id: result._id,
          bankName: result.bankName || '',
          routingNumber: result.routingNumber || '',
          accountNumber: result.accountNumber || '',
          accountType: result.accountType || '',
        });
        setAchExists(true);
      }
    } catch (err) {
      console.log('No ACH info found or error retrieving:', err);
      setAchExists(false);
    }
  }

  // ---------- Lifecycle ----------
  useEffect(() => {
    fetchUserData();
    fetchACHData();
  }, []);

  // ---------- Star-out helper for read-only display ----------
  function starOutDigits(value = '') {
    if (!value) return 'No Data';
    if (value.length <= 4) return '*'.repeat(value.length);
    const visiblePart = value.slice(-4);
    const starredPart = '*'.repeat(value.length - 4);
    return starredPart + visiblePart;
  }

  // ---------- Helper for missing data ----------
  function renderData(field) {
    if (!field || field === 'Missing Data') {
      return <span className="text-yellow-600 font-semibold">Missing Data</span>;
    }
    return field;
  }

  // ---------- ACH Save/Cancel ----------
  function handleACHCancel() {
    setIsEditingACH(false);
    setEditACHForm({ ...achData });
  }

  async function handleACHSave() {
    try {
      const accountNumber = editACHForm.accountNumber.trim();
      const routingNumber = editACHForm.routingNumber.trim();
      if (!/^\d{8,12}$/.test(accountNumber)) {
        toast.error('Account Number must be 8-12 digits.');
        return;
      }
      if (!/^\d{9}$/.test(routingNumber)) {
        toast.error('Routing Number must be exactly 9 digits.');
        return;
      }

      const updatedACH = {
        bankName: editACHForm.bankName,
        routingNumber,
        accountNumber,
        accountType: editACHForm.accountType,
        type: 'customer',
      };

      if (editACHForm._id) {
        // update existing
        await api_update(`${process.env.REACT_APP_API_URL}/ach-info/${editACHForm._id}`, updatedACH);
      } else {
        // create new
        await api_post(`${process.env.REACT_APP_API_URL}/ach-info/`, updatedACH);
      }
      toast.success('ACH Info updated!');
      setIsEditingACH(false);
      fetchACHData();
    } catch (err) {
      toast.error('Error saving ACH info.');
      console.error('ACH save error:', err);
    }
  }

  return (
    <div className="container mx-auto py-8 space-y-6 max-w-5xl animate-fadeIn transition-opacity duration-300">
      {/* Profile Section */}
      <div className="bg-primary rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all">
        <div className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <PersonStandingIcon className="h-6 w-6 text-yellow-500" />
            <h1 className="text-2xl font-bold text-gray-900">
              Profile Information
            </h1>
          </div>

          <div className="grid grid-cols-2 gap-6 mt-4">
            {/* First Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <p className="mt-1 text-sm text-gray-900">
                {renderData(userData.firstName)}
              </p>
            </div>

            {/* Last Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <p className="mt-1 text-sm text-gray-900">
                {renderData(userData.lastName)}
              </p>
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <p className="mt-1 text-sm text-gray-900">
                {renderData(userData.email)}
              </p>
            </div>

            {/* Business Name */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Business Name</label>
              <p className="mt-1 text-sm text-gray-900">
                {renderData(userData.businessName)}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ACH Information Section */}
      <div className="bg-primary rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all">
        <div className="p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Banknote className="h-6 w-6 text-yellow-500" />
              <h1 className="text-2xl font-bold text-gray-900">ACH Information</h1>
            </div>
            {!achExists && !isEditingACH ? (
              <button
                onClick={() => setIsEditingACH(true)}
                className="inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-primary text-sm font-medium text-yellow-600 hover:bg-yellow-50 transition-all"
              >
                + Add ACH Info
              </button>
            ) : !isEditingACH ? (
              <button
                onClick={() => setIsEditingACH(true)}
                className="inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-primary text-sm font-medium text-gray-700 hover:bg-gray-50 transition-all"
              >
                <Pencil className="h-4 w-4 mr-2" />
                Edit ACH Info
              </button>
            ) : null}
          </div>

          {/* Show ACH if exists and not editing */}
          {achExists && !isEditingACH && (
            <div className="grid grid-cols-2 gap-6 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                <p className="mt-1 text-sm text-gray-900">
                  {renderData(achData.bankName)}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Account Type</label>
                <p className="mt-1 text-sm text-gray-900">
                  {achData.accountType || <span className="text-yellow-600 font-semibold">Missing Data</span>}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Routing Number</label>
                <p className="mt-1 text-sm text-gray-900">
                  {achData.routingNumber
                    ? starOutDigits(achData.routingNumber)
                    : <span className="text-yellow-600 font-semibold">Missing Data</span>}
                </p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Account Number</label>
                <p className="mt-1 text-sm text-gray-900">
                  {achData.accountNumber
                    ? starOutDigits(achData.accountNumber)
                    : <span className="text-yellow-600 font-semibold">Missing Data</span>}
                </p>
              </div>
            </div>
          )}

          {/* If editing ACH */}
          {isEditingACH && (
            <div className="grid grid-cols-2 gap-6 mt-4">
              {/* Bank Name */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Bank Name</label>
                <TextField
                  value={editACHForm.bankName}
                  onChange={(val) => {
                    setEditACHForm({ ...editACHForm, bankName: val });
                  }}
                  inputClass="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-primary"
                  placeholder="Enter bank name"
                />
              </div>

              {/* Account Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Account Type</label>
                <SelectField
                  value={editACHForm.accountType}
                  onChange={(val) => setEditACHForm({ ...editACHForm, accountType: val })}
                  options={[
                    { text: 'Checking', value: 'Checking' },
                    { text: 'Savings', value: 'Savings' },
                  ]}
                  placeholder="Select Account Type"
                />
              </div>

              {/* Routing Number */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Routing Number</label>
                <input
                  type="text"
                  value={editACHForm.routingNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d{0,9}$/.test(value)) {
                      setEditACHForm({ ...editACHForm, routingNumber: value });
                    }
                  }}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                />
                {editACHForm.routingNumber && !/^\d{9}$/.test(editACHForm.routingNumber) && (
                  <p className="text-red-600 text-sm">
                    Routing Number must be exactly 9 digits.
                  </p>
                )}
              </div>

              {/* Account Number */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Account Number</label>
                <input
                  type="text"
                  value={editACHForm.accountNumber}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (/^\d{0,12}$/.test(val)) {
                      setEditACHForm({ ...editACHForm, accountNumber: val });
                    }
                  }}
                  required
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                />
                {editACHForm.accountNumber && !/^\d{8,12}$/.test(editACHForm.accountNumber) && (
                  <p className="text-red-600 text-sm">
                    Account Number must be 8-12 digits.
                  </p>
                )}
              </div>

              <div className="col-span-2 flex gap-2 justify-end">
                <button
                  onClick={handleACHSave}
                  className="inline-flex items-center px-4 py-2 rounded-md border border-transparent bg-green-600 text-sm font-medium text-white hover:bg-green-700 transition-all"
                >
                  <Check className="h-4 w-4 mr-2" />
                  Save
                </button>
                <button
                  onClick={handleACHCancel}
                  className="inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-primary text-sm font-medium text-red-600 hover:bg-red-50 transition-all"
                >
                  <X className="h-4 w-4 mr-2" />
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Connections Section */}
      <div className="bg-primary rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-all">
        <div className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <CreditCard className="h-6 w-6 text-yellow-500" />
            <h1 className="text-2xl font-bold text-gray-900">Connections</h1>
          </div>

          {/* Stripe Subscription */}
          <div className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-all">
            <div className="flex items-center gap-3">
              <CreditCard className="h-5 w-5 text-gray-600" />
              <div>
                <p className="font-medium text-gray-900">Stripe Subscription</p>
                <p className="text-sm text-gray-500">
                  {renderData(userData.stripeSubscriptionStatus)}
                </p>
              </div>
            </div>
            <div className="flex items-center">
              <StripeSubscriptionButton user={userData} />
            </div>
          </div>

          {/* QuickBooks Connection */}
          {quickbooksEnabled && (
            <div className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-all mt-4">
              <div className="flex items-center gap-3 w-1/2">
                <Building2 className="h-5 w-5 text-gray-600" />
                <div>
                  <p className="font-medium text-gray-900">QuickBooks</p>
                  <p className="text-sm text-gray-500">Click to connect or manage</p>
                </div>
              </div>
                <QuickbooksButton user={userData} text="Manage QuickBooks" containerClassName='!w-1/3 ml-auto' />
            </div>
          )}

          {/* Plaid Connection */}
          {plaidEnabled && (
            <div className="flex items-center justify-between p-4 border rounded-lg hover:bg-gray-50 transition-all mt-4">
              <div className="flex items-center gap-3">
                <Link2 className="h-5 w-5 text-gray-600" />
                <div>
                  <p className="font-medium text-gray-900">Plaid</p>
                  <p className="text-sm text-gray-500">Connect your bank account via Plaid</p>
                </div>
              </div>
                <PlaidButton user={userData} text="Manage Bank Account" containerClassName='!w-1/3 ml-auto' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProfileForm;