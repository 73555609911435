import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api.service";
import CreditAppModal from "../credit-app-modal";
import LoanSearch from "./LoanSearch";
import LoanCard from "./LoanCard";
import Loading from "../loading";

export default function FinancingDashboard() {
  const [showCreditApp, setShowCreditApp] = useState(false);
  const [loans, setLoans] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [achInfo, setAchInfo] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [showACHBanner, setShowACHBanner] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    api(`${process.env.REACT_APP_API_URL}/loan`)
      .then((response) => {
        if (Array.isArray(response)) {
          setLoans(response);
        } else {
          console.error("Loans not returned as array", response);
        }
      })
      .catch((err) => console.error("Error fetching loans:", err))
      .finally(() => setLoading(false));

    api(`${process.env.REACT_APP_API_URL}/transaction`)
      .then((response) => {
        if (Array.isArray(response)) {
          setTransactions(response);
        } else {
          console.error("Transactions not array", response);
        }
      })
      .catch((err) => console.error("Error fetching transactions:", err));

    api(`${process.env.REACT_APP_API_URL}/ach-info/owner/customer`)
      .then((response) => {
        if (response && !response.error) {
          setAchInfo(response);
        } else {
          console.log("No user ACH info found");
        }
      })
      .catch((err) => console.error("Error fetching ACH info:", err));
  }, []);

  function formatCurrency(value) {
    if (typeof value !== "number" || isNaN(value)) {
      return "Missing";
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  function formatDate(dateStr) {
    if (!dateStr) return "Missing";
    const d = new Date(dateStr);
    if (isNaN(d.getTime())) return "Missing";
    return d.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }

  // Summaries
  const activeLoans = loans.filter((loan) => loan.status === "active");
  const inactiveLoans = loans.filter((loan) => loan.status === "inactive");

  // totalActiveBalance => sum of each next unpaid principalBalance
  let totalActiveBalance = 0;
  let soonestPaymentDate = null;
  for (const loan of activeLoans) {
    if (!Array.isArray(loan.paymentSchedule)) continue;
    const unpaid = loan.paymentSchedule.find((p) => p.status === "unpaid");
    if (unpaid) {
      totalActiveBalance += unpaid.principalBalance || 0;
      if (!soonestPaymentDate || new Date(unpaid.dueDate) < soonestPaymentDate) {
        soonestPaymentDate = new Date(unpaid.dueDate);
      }
    } else {
      totalActiveBalance += loan.principal || 0;
    }
  }

  // If earliest payment due < 7 days => show banner
  useEffect(() => {
    if (soonestPaymentDate) {
      const now = new Date();
      const diffDays = (soonestPaymentDate.getTime() - now.getTime()) / (1000 * 3600 * 24);
      if (diffDays <= 2 && diffDays >= 0) {
        setShowACHBanner(true);
      }
    }
  }, [soonestPaymentDate]);

  const now = new Date();
  const next30 = new Date(now);
  next30.setDate(next30.getDate() + 30);
  let dueNext30Sum = 0;

  for (const loan of activeLoans) {
    if (!Array.isArray(loan.paymentSchedule)) continue;

    for (const pmt of loan.paymentSchedule) {
      if (pmt.status !== "unpaid") continue;

      const dueDate = new Date(pmt.dueDate);
      if (isNaN(dueDate)) continue; // Skip invalid dates

      if (dueDate >= now && dueDate <= next30) {
        dueNext30Sum += pmt.totalDue || 0;
      }
    }
  }

  // Filter
  const displayedLoans = loans.filter((loan) => {
    const s = searchTerm.toLowerCase();
    if (loan._id?.toLowerCase().includes(s)) return true;
    if (typeof loan.principal === "number" && loan.principal.toString().includes(s)) return true;
    if (loan.bill?._id?.toLowerCase().includes(s)) return true;
    if (loan.project?._id?.toLowerCase().includes(s)) return true;
    return false;
  });

  const handleOpenCreditApp = () => {
    setShowCreditApp(true);
  };

  const handleCloseCreditApp = () => {
    setShowCreditApp(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mx-auto py-8 px-4 space-y-6 max-w-5xl">
      {/* Banner for upcoming payment */}
      {showACHBanner && (
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 rounded shadow-md relative">
          <span
            onClick={() => setShowACHBanner(false)}
            className="cursor-pointer text-yellow-700 absolute right-2 top-2"
          >
            &times;
          </span>
          <p className="text-yellow-800 font-semibold">
            You have a payment coming up soon! Please ensure your ACH info is up to date.
          </p>
          <button
            onClick={() => navigate("/settings")}
            className="bg-yellow-600 text-white px-3 py-1 mt-2 rounded hover:bg-yellow-700"
          >
            Update ACH Info
          </button>
        </div>
      )}

      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Financing Dashboard</h1>
        <button
          onClick={handleOpenCreditApp}
          className="border rounded px-4 py-2 bg-primary hover:bg-gray-50 transition text-gray-700"
        >
          View Credit Application
        </button>
      </div>

      {/* Summary Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="border p-4 rounded bg-primary shadow-sm">
          <h2 className="text-lg font-medium">Total Active Balance</h2>
          <p className="text-2xl font-bold">{formatCurrency(totalActiveBalance)}</p>
          <p className="text-sm text-gray-600">{activeLoans.length} active loan(s)</p>
        </div>
        <div className="border p-4 rounded bg-primary shadow-sm">
          <h2 className="text-lg font-medium">Due in Next 30 Days</h2>
          <p className="text-2xl font-bold">{formatCurrency(dueNext30Sum)}</p>
          <p className="text-sm text-gray-600">Upcoming payments total</p>
        </div>
        <div className="border p-4 rounded bg-primary shadow-sm">
          <h2 className="text-lg font-medium">Total Loans</h2>
          <p className="text-2xl font-bold">{loans.length}</p>
          <p className="text-sm text-gray-600">
            {activeLoans.length} Active, {inactiveLoans.length} Inactive
          </p>
        </div>
      </div>

      {/* Loan Search */}
      <LoanSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {/* Loans list */}
      <div className="border p-4 rounded bg-secondary flex flex-col gap-4">
        <h2 className="text-lg font-semibold mb-4">Your Loans</h2>
        {displayedLoans.length === 0 && <p className="text-gray-500">No loans found.</p>}
        {displayedLoans.map((loan) => (
          <LoanCard
            key={loan._id}
            loan={loan}
            transactions={transactions.filter((t) => t.loanId === loan._id)}
            formatCurrency={formatCurrency}
            formatDate={formatDate}
          />
        ))}
      </div>

      {showCreditApp && (
        <CreditAppModal open={showCreditApp} onClose={handleCloseCreditApp} />
      )}
    </div>
  );
}