import React, { useState, useEffect } from "react";
import { api } from "../api.service";
import CreditApplicationForm from "../forms/credit-application-form";
import FinancingDashboard from "./financing-dashboard";

export default function FinancingPage() {
  console.log("FinancingPage component loaded.");

  const [creditAppStatus, setCreditAppStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("FinancingPage mounting - fetching credit application status.");
    api(`${process.env.REACT_APP_API_URL}/credit-application/application`)
      .then((res) => {
        console.log("FinancingPage credit-application/application response:", res);
        if (res?.status) {
          setCreditAppStatus(res.status.toLowerCase());
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("FinancingPage error fetching credit application status:", err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="p-6">
        <p className="text-xl font-semibold">Loading Financing...</p>
      </div>
    );
  }

  if (creditAppStatus === "verified") {
    console.log("FinancingPage: credit app is verified. Showing FinancingDashboard.");
    return <FinancingDashboard />;
  } else {
    console.log("FinancingPage: credit app is NOT verified. Showing CreditApplicationForm.");
    return (
      <div className="p-6">
        <CreditApplicationForm />
      </div>
    );
  }
}