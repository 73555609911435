import PageHeader from '../components/page-header';
import TextField from './fields/text-field';
import FormWrapper from './form-wrapper';
import TextAreaField from './fields/text-area-field';
import { useNavigate, useParams } from 'react-router-dom';

export default function ContentForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id ?? props.id;

  return (
    <>
      <PageHeader headline="Manage Content" showBackButton></PageHeader>
      <div className="pt-4 sm:px-4 lg:max-w-4xl lg:mx-auto">
        <FormWrapper
          url="admin/content"
          id={id}
          {...props}
          callback={() => navigate(-1)}
        >
          {(values, setValues) => (
            <>
              <TextField
                label="Title"
                value={values.title}
                onChange={(val) => setValues({ ...values, title: val })}
              />
              <TextAreaField
                label="Description"
                value={values.description}
                onChange={(val) => setValues({ ...values, description: val })}
              />
              <TextField
                label="URL (include https://)"
                value={values.url}
                onChange={(val) => setValues({ ...values, url: val })}
              />
              {/* New Category Field */}
              <TextField
                label="Category (optional)"
                value={values.category}
                onChange={(val) => setValues({ ...values, category: val })}
              />
            </>
          )}
        </FormWrapper>
      </div>
    </>
  );
}