import React from "react";
import FinancingDashboard from "../components/financing-dashboard/FinancingDashboard";

/**
 * This page is now a wrapper around the new FinancingDashboard component.
 * We keep the route structure the same, but move the old mock code into
 * the new separate files with real data fetching.
 */
export default function FinancingDashboardPage() {
  return <FinancingDashboard />;
}