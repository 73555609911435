import PageHeader from './components/page-header';
import Loading from './components/loading';
import { useEffect, useState } from 'react';
import { api, api_delete } from './api.service';
import { useNavigate } from 'react-router-dom';
import Button from './components/button';
import Table from './components/table';
import Confirm from './components/confirm';

export default function AdminContent() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [deleteId, setDeleteId] = useState();

  const deleteConfirm = () => {
    api_delete(
      `${process.env.REACT_APP_API_URL}/admin/content/${deleteId}`
    ).then(() => {
      api(`${process.env.REACT_APP_API_URL}/admin/content`)
        .then((x) => setData(x))
        .then(() => setDeleteId(null));
    });
  };

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/admin/content`).then((x) =>
      setData(x)
    );
  }, []);

  if (!data) return <Loading />;

  return (
    <>
      <Confirm
        open={!!deleteId}
        cancel={() => setDeleteId(false)}
        confirm={() => deleteConfirm(deleteId)}
      />
      <PageHeader
        headline={'Content'}
        actions={
          <Button
            text="Add Content Item"
            onClick={() => navigate('/admin/manage-content/new')}
          />
        }
      />
      <div className="flex">
        <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
          <div>
            <Table
              data={data.map((x) => ({
                ...x,
                edit: (
                  <span
                    className="underline cursor-pointer"
                    onClick={() => navigate(`/admin/manage-content/${x._id}`)}
                  >
                    Edit
                  </span>
                ),
                deleteCell: (
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setDeleteId(x._id)}
                  >
                    Delete
                  </span>
                ),
              }))}
              columns={[
                {
                  Header: 'Title',
                  accessor: 'title',
                },
                {
                  Header: 'Category',
                  accessor: 'category',
                },
                {
                  Header: 'Edit',
                  accessor: 'edit',
                },
                {
                  Header: 'Delete',
                  accessor: 'deleteCell',
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}