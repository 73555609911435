import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';
import { getStatusClass } from '../utils';

/**
 * A list page that shows all Loans. Admin can click "View Details" to
 * see the loan detail page at /admin/loan/detail/:id
 */

const LOANS_TABLE_COLUMNS = [
  {
    Header: 'Loan ID',
    accessor: 'loanId',
  },
  {
    Header: 'Principal',
    accessor: 'principal',
    Cell: ({ value }) => (value ? '$' + value.toFixed(2) : 'N/A'),
  },
  {
    Header: 'Interest Rate',
    accessor: 'interestRate',
    Cell: ({ value }) => (value ? (value * 100).toFixed(2) + '%' : 'N/A'),
  },
  {
    Header: 'Term (weeks)',
    accessor: 'termLengthWeeks',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <span className={`${getStatusClass(value)} capitalize font-bold`}>
        {value}
      </span>
    ),
  },
  {
    Header: 'Created At',
    accessor: 'createdAt',
    Cell: ({ value }) =>
      value ? new Date(value).toLocaleDateString() : 'N/A',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

const EXPORT_TABLE_COLUMNS = [
  'Loan ID',
  'Principal',
  'Interest Rate',
  'Term (weeks)',
  'Status',
  'Created At',
];

const CellViewDetails = ({ onClick }) => (
  <span
    className="underline cursor-pointer text-blue-500"
    onClick={onClick}
  >
    View Details
  </span>
);

export default function AdminLoanList() {
  const navigate = useNavigate();
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchLoans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLoans = async () => {
    setLoading(true);
    try {
      // The /api/loan route calls getLoans() which returns all if admin
      const result = await api(`${process.env.REACT_APP_API_URL}/loan`);
      setLoans(result);
    } catch (err) {
      console.error(err);
      setError('Error loading loans data');
      toast.error('Failed to load loans');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!loans || loans.length === 0)
    return (
      <div className="p-4">
        <PageHeader headline="Loans" />
        <p>No loans found.</p>
      </div>
    );

  // Prepare table data
  const tableData = loans.map((loan) => ({
    loanId: loan._id,
    principal: loan.principal,
    interestRate: loan.interestRate,
    termLengthWeeks: loan.termLengthWeeks,
    status: loan.status || 'unknown',
    createdAt: loan.createdAt,
    actions: (
      <CellViewDetails
        key={loan._id}
        onClick={() => navigate(`/admin/loan/detail/${loan._id}`)}
      />
    ),
  }));

  // Also prepare an export-friendly version
  const exportData = loans.map((loan) => ({
    'Loan ID': loan._id,
    Principal: loan.principal,
    'Interest Rate': loan.interestRate,
    'Term (weeks)': loan.termLengthWeeks,
    Status: loan.status || 'unknown',
    'Created At': loan.createdAt
      ? new Date(loan.createdAt).toLocaleDateString()
      : 'N/A',
  }));

  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />
      <PageHeader headline="Loans" />
      <div className="flex">
        <div className="w-full max-w-5xl pb-20 mx-auto my-10 2xl:max-w-6xl">
          <Table
            columns={LOANS_TABLE_COLUMNS}
            exportColumns={EXPORT_TABLE_COLUMNS}
            data={tableData}
            exportData={exportData}
          />
        </div>
      </div>
    </>
  );
}